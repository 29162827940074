import { useState, useEffect } from 'react'

import analytics from '@mobi/libraries/analytics'
import { goTo } from 'utils'

import services from '../services'

const usePropertyDataUnavailable = () => {
  const [loading, setIsLoading] = useState(false)

  useEffect(() => {
    analytics.track('pageLoad', {
      currentPage: 'proposta/dados-imovel/indisponivel'
    })
  }, [])

  const onSubmit = async () => {
    analytics.track('buttonClick', {
      currentPage: 'proposta/dados-imovel/indisponivel',
      detail: 'click-step-dados-imovel-indisponivel'
    })
    setIsLoading(true)

    const requestBody = {
      has_property: false,
      property_value: 0
    }

    return services
      .putProperty(requestBody)
      .then(({ next_step }) => goTo(next_step))
      .finally(() => setIsLoading(false))
  }

  return {
    onSubmit,
    loading
  }
}

export default usePropertyDataUnavailable
